import SectionTitle from "../../../components/section-title";

const Objectives = () => {
    return (
        <section className="feature-section bg-white">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            classOption="text-center"
                            subTitle="Our Objectives"
                            title="<span>Compassionate Care</span> Saving Lives"
                        />
                    </div>
                </div>
                <div className="row pb-20"
                    data-aos="fade-right"
                    data-aos-duration="1300"
                >
                    <div className="col-lg-12">
                        <ul className="obj-list">
                            <li>Increase public awareness through healthcare advocacy</li>
                            <li>Provide high quality care at affordable price to the people of Lumbini and Karnali Province</li>
                            <li>To grow as a complete medical care service provider to the People</li>
                            <li>Prevention and cure of all preventable and curable diseases</li>
                            <li>Improve clinical access and service to the people of Lumbini and Karnali Province</li>
                            <li>Improve quality of life by providing curative care through high quality medical intervention</li>
                            <li>Prevent, treat and eliminate all kind of diseases</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Objectives;
