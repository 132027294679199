import React, { useEffect, useState } from "react";
import Loader from "../components/loader";
import NewsListItem from "../components/newslistItem";
import SEO from "../components/seo";
import { contactInfo } from "../data/contactInfo";
import { RequireAuth } from "../hoc/requireAuth";
import Layout from "../layouts";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import { getSection } from "../repositories/section";

export default function News() {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getSection({ type: "news" });
        setNews(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <Layout>
        <SEO title={`News | ${contactInfo?.name}`} />
        <div className="wrapper">
          <Header />
          <div className="team-area team-default-area">
            <div className="container">
              <h1 className="">News</h1>
              {!loading ? (
                <div className="row mt-4 text-center">
                  {news?.length > 0 ? (
                    <>
                      {news?.map((item, i) => (
                        <div className="col-md-4 text-left mb-4" key={i}>
                          <NewsListItem data={item} />
                        </div>
                      ))}
                    </>
                  ) : (
                    "No items found !"
                  )}
                </div>
              ) : (
                <Loader />
              )}
            </div>
          </div>
          <Footer />
        </div>
      </Layout>
    </React.Fragment>
  );
}
