import axios from "axios";
import { baseUrl } from "../globalFunctions";

const endpoint = `${baseUrl}/section`;

export const getSection = (data) => {
  const options = {
    method: "get",
    url: `${endpoint}?type=${data?.type}`,
  };
  return axios(options);
};

export const getSectionById = (data) => {
  const options = {
    method: "get",
    url: `${endpoint}/${data?.id || ""}`,
  };
  return axios(options);
};

export const postImage = (data) => {
  const options = {
    method: "post",
    url: `${endpoint}/image?section_guid=${data?.id}`,
    data: data,
  };
  return axios(options);
};

export const postSection = (data) => {
  const options = {
    method: "post",
    url: `${endpoint}`,
    data: data,
  };
  return axios(options);
};
