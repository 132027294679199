import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { dateFormatter, getImageUrl, htmlToText } from "../../globalFunctions";

const NewsListItem = ({ data }) => {
  return (
    <div class="news-card">
      <div class="news-card-header">
        <img src={getImageUrl(data?.images[0]?.image_url)} alt={data?.title} />
      </div>
      <div class="news-card-body">
        <div className="news-date">Published on {dateFormatter(data?.created_at)}</div>
        <h4 className="clamp-2">
          <Link to={`/news-detail/${data?.section_guid}`}>{data?.title}</Link>
        </h4>
        <p className="clamp-2">
          {htmlToText(data?.content)}
        </p>
      </div>
    </div>
  );
};

NewsListItem.propTypes = {
  data: PropTypes.object,
};

export default NewsListItem;
