import Layout from "../layouts/index.jsx";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import { contactInfo } from "../data/contactInfo.js";

export default function About() {
    return (
        <Layout>
            <SEO title={`Contact | ${contactInfo?.name}`} />
            <div className="wrapper">
                <Header />
                <div className="container about-page" style={{ padding: '100px 0px' }}>
                    <section>
                        <div className="section-title mb-0">
                            <h2 className="title"><span>Introduction</span></h2>
                        </div>
                        <p>Rapti Hospital Pvt. Ltd., established in 2063 BS, is a reputable private hospital located in Tulsipur, Dang. Specializing in pediatrics, general surgery, gynecology, and orthopedics, the hospital has gained recognition for delivering high-quality healthcare services. With a dedicated team of medical professionals and advanced facilities, Rapti Hospital provides comprehensive care to its patients.</p>
                        <p>
                            The hospital's pediatric department offers specialized care for infants, children, and adolescents, while the general surgery, gynecology, and orthopedics departments provide a range of services using modern technology and skilled healthcare providers. Rapti Hospital is committed to patient safety and aims to improve the health and well-being of the community it serves. Located in Tulsipur, Dang, it has become a trusted healthcare institution in the region</p>
                    </section>

                    <section>
                        <div className="section-title mb-0">
                            <h2 className="title"><span>Message from</span> the Chairman</h2>
                        </div>
                        <div class="director-message">
                            <div class="director-content">
                                <img class="director-image" src="/img/team/chairman.jpg" alt="Chairman" />

                                <p>All set to become a landmark in the field of medical care, Rapti Life Care Hospital has gone leaps and bounds to deliver its mission of bringing quality healthcare services for all. We are aligned with our guiding principle "Caring for what matters most", which is the virtue by which every individual at Rapti Life Care Hospital.The team of our expert medical professionals will constantly endeavour to provide accessible, affordable and best available healthcare services in Tulsipur Dang. Since its commencement, this hospital has continuously evolved in the field of Hospital Service. </p>

                                <p>Great ideologies have greater responsibilities and the goals in healthcare are to safeguard, encourage and at times cultivate the social fabric of the society as a whole. We at Rapti Life Care Hospital are earnestly and continuously striving our best to scale newer heights in the pursuit of excellence. Our hospital has become a name that spells assurance for the well-being of the patients. We are committed to make available to the public the benefits of the most modern and technological developments in the field of medical sciences which are a blend of technology along with impeccable patient care. The hospital has attained the highest standards of healthcare along with administrative efficiency work. </p>

                                <p>With a career in Medicine spanning over Eighteen years I stand here grateful to the almighty, despite being from a humble background, I met a few gracious souls who guided me in my journey of life. My father solicited inspiration and taught me the value of discipline, perseverance and a regulated life. Rapti Life Care Hospital stringently conforms the healthcare regulations due to which the hospital is now an abode to patients from surrounding District like Salyan ,Rukum,Pyuthan ,Rolpa and Arghakhachi. </p>

                                <p>Through our essence of high teamwork, we bring together all necessary disciplines and skills from many resources of our organization to serve our patients better and attempt to set a league of its own. </p>

                                <p>Life is our greatest gift, where every moment is as new as it is special. Life is about living every second to the fullest and about making every day an experience to enjoy and cherish. We at Rapti Life Care Hospital every moment spent in making your life healthier. We have a dream of delivering Ecellence Health Care Service in province Five with the best medical talent and we aim that our hospital leaves an indelible mark in the heart of every patient and their family members. </p>
                            </div>

                        </div>
                    </section>

                    <section>
                        <div className="section-title mb-0">
                            <h2 className="title"><span>Mission</span> and Vision</h2>
                        </div>
                        <h3>Mission:</h3>
                        <p>Rapti Hospital Pvt. Ltd. is dedicated to providing exceptional healthcare services to our patients with compassion, integrity, and innovation. Our mission is to deliver comprehensive medical care, focusing on pediatrics, general surgery, gynecology, and orthopedics, while upholding the highest standards of quality and patient safety. We strive to make a positive impact on the health and well-being of the community we serve, continuously improving our services through advanced technology, professional expertise, and a patient-centered approach.</p>
                        <h3>Vision:</h3>
                        <p>Our vision at Rapti Hospital is to be a leading healthcare institution in the region, recognized for excellence in patient care, medical education, and research. We aim to create a nurturing and inclusive environment where patients, their families, and our healthcare professionals collaborate to achieve optimal health outcomes. Through continuous innovation, we seek to pioneer new approaches in healthcare delivery, ensuring accessibility, affordability, and the highest level of medical expertise. Our ultimate vision is to contribute to the overall well-being of the society and set a benchmark for healthcare excellence in Nepal.</p>
                    </section>
                </div>

                <Footer />
                <ScrollToTop />
            </div>
        </Layout>
    );
}
