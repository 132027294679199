import React, { useEffect, useState } from "react";
import Layout from "../layouts/index.jsx";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import SEO from "../components/seo";
import { contactInfo } from "../data/contactInfo.js";
import SectionTitle from "../components/section-title/index.jsx";
import Team from "../components/team";
import { getTeams } from "../repositories/team.js";
import Loader from "../components/loader/index.js";

export default function TeamsPage() {
    const [loading, setLoading] = useState(false);
    const [teams, setTeams] = useState([]);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await getTeams();
                const data = response?.data?.data
                setTeams(data);
                const cats = data.map(item => item.category);
                const cateSet = new Set(cats);
                setCategories([...cateSet]);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };
        fetchData();
    }, []);

    return (
        <React.Fragment>
            <Layout>
                <SEO title={`Team | ${contactInfo?.name}`} />
                <div className="wrapper">
                    <Header />
                    <div className="team-area team-default-area bg-gray">
                        {!loading ? (
                            <>
                                {categories?.map((item, i) => {
                                    const splittedTitle = item.split(' ');
                                    const title1 = splittedTitle?.slice(0, splittedTitle?.length - 1);
                                    const title2 = splittedTitle[splittedTitle?.length - 1];
                                    const relatedTeams = teams.filter(el => el.category === item);
                                    return (
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-12">
                                                    <SectionTitle
                                                        classOption="text-center"
                                                        subTitle=""
                                                        title={`<span>${title1}</span> ${title2}`}
                                                    />
                                                </div>
                                            </div>

                                            <div
                                                className="row"
                                                data-aos="fade-up"
                                                data-aos-duration="1300"
                                            >
                                                {relatedTeams?.map((single, key) => (
                                                    <div className="col-md-6 col-lg-4 mb-4">
                                                        <Team key={key} data={single} teamCard />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    )
                                }
                                )}

                            </>
                        ) : (
                            <Loader />
                        )}
                    </div>
                    <Footer />
                </div>
            </Layout>
        </React.Fragment>
    );
}
