import axios from "axios";
import { baseUrl } from "../globalFunctions";

const endpoint = `${baseUrl}/lab-report`;

// assign token if authorized
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");

export const getReport = (data) => {
  const options = {
    method: "get",
    url: `${endpoint}/patient?patient_id=${data.id}`,
  };
  return axios(options);
};

export const getReportPdf = (data) => {
  const options = {
    method: "get",
    url: `${endpoint}/pdf?report_guid=${data.id}`,
  };
  return axios(options);
};
