export const baseUrl = process.env.REACT_APP_BASE_URL;

export const fileUrl = process.env.REACT_APP_FILE_URL;
export const imageUrl = process.env.REACT_APP_IMAGE_URL;

export const getImageUrl = (url) => {
  if (url) {
    if (url?.charAt(0) === "/") return `${imageUrl}${url}`;
    else return `${imageUrl}/${url}`;
  }
  else return '/img/logo.png';
};

export const altImage = "/img/logo.png";

export const dateFormatter = (value) => {
  let date = new Date(value);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let dt = date.getDate();

  if (dt < 10) {
    dt = "0" + dt;
  }
  if (month < 10) {
    month = "0" + month;
  }

  return year + "-" + month + "-" + dt;
};

export const htmlToText = (html) => {
  const text = html.replace(/<[^>]+>/g, '');
  return text;
}