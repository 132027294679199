import Button from "../button";
import PropTypes from "prop-types";

const Intro = ({ data }) => {
    return (
        <div
            className="intro-section"
            style={{
                backgroundImage: `url(${data.backgroundImage})`,
            }}
        >
            <div className="bg-overlay d-flex align-items-center">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="slider-content">
                                <p className="text animated delay1">
                                    {data.subTitle}
                                </p>
                                <h2
                                    className="title animated delay2"
                                    dangerouslySetInnerHTML={{
                                        __html: data.title,
                                    }}
                                />
                                <Button
                                    path={"/services"}
                                    classOption="btn btn-outline-secondary animated delay2"
                                    text="our services"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

Intro.propTypes = {
    data: PropTypes.object,
};

export default Intro;
