import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Button from "../../components/button";
import Loader from "../../components/loader";
import SectionTitle from "../../components/section-title";
import ServiceItem from "../../components/service-item";
import { getDepartments } from "../../repositories/department";

const ServiceContainer = () => {
  const [loading, setLoading] = useState(false);
  const [departments, setDepartments] = useState([]);
  const location = useLocation();

  let isServicePage = location.pathname.includes("/services");

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getDepartments();
        let data = await response?.data?.data;
        if (isServicePage) {
          setDepartments(data);
        } else {
          setDepartments(data.slice(0, 8));
        }
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);
  return (
    <div className="service-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <SectionTitle
              classOption="text-center"
              subTitle="Our services"
              title="<span>Best Solution</span> For Your Health"
            />
          </div>
        </div>
        <div className="row">
          {!loading ? (
            <div className="col-lg-12">
              <div
                className="row service-style2"
                data-aos="fade-up"
                data-aos-duration="1200"
              >
                {departments?.map((item, key) => {
                  return <ServiceItem key={key} data={item} />;
                })}
              </div>
              {!isServicePage && (
                <div className="d-flex justify-content-center">
                  <Button
                    path={"/services"}
                    classOption="book-now-btn mt-4"
                    text="View All"
                  />
                </div>
              )}
            </div>
          ) : (
            <Loader />
          )}
        </div>
      </div>
    </div>
  );
};

export default ServiceContainer;
