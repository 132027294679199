import SocialIcon from "../../components/social-icon";
import Logo from "../../components/logo";

const Footer = () => {
    let date = new Date();
    return (
        <footer className="footer-area">
            <div className="footer-bottom">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-sm-12">
                            <div className="widget-copyright">
                                <p>
                                    &copy; Copyright {date?.getFullYear()} Rapti
                                    Lifecare Hospital (RLCH) All Rights Reserved{" "}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
