import React, { useEffect, useState } from "react";
import Layout from "../layouts/index.jsx";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import { contactInfo } from "../data/contactInfo.js";
import SectionTitle from "../components/section-title/index.jsx";
import { getReport, getReportPdf } from "../repositories/labReport.jsx";
import { dateFormatter, fileUrl } from "../globalFunctions.js";
import Loader from "../components/loader/index.js";
import { RequireAuth } from "../hoc/requireAuth.js";

export default function LabReports() {
  const [loading, setLoading] = useState(true);
  const [requesting, setRequesting] = useState(false);
  const [patientId, setPatientId] = useState("");
  const [reports, setReports] = useState([]);

  useEffect(() => {
    let id = localStorage.getItem("patientId");
    const fetchData = async () => {
      try {
        const response = await getReport({ id });
        setReports(response.data.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleReportClick = async (id) => {
    setRequesting(true);
    try {
      const response = await getReportPdf({ id });
      setFiles(response.data.data);
      setRequesting(false);
    } catch (error) {
      console.error(error);
      setRequesting(false);
    }
  };

  return (
    <React.Fragment>
      <RequireAuth>
        <Layout>
          <SEO title={`Report | ${contactInfo?.name}`} />
          <div className="wrapper">
            <Header />
            <div
              className="team-area team-default-area bg-gray"
              style={{ paddingTop: "50px" }}
            >
              <div className="container">
                <div>
                  <h2 className="section-header">Reports </h2>
                  {!loading ? (
                    <div className="row w-full mt-2">
                      {reports?.length > 0
                        ? reports?.map((item) => (
                            <a
                              key={item.report_guid}
                              className={"col-md-6 col-lg-3 mb-3"}
                              style={{
                                color: "var(--primary-color)",
                                cursor: "pointer",
                              }}
                              href={`/lab-report/${item.report_guid}`}
                            >{`Report from ${dateFormatter(
                              item.created_at
                            )}`}</a>
                          ))
                        : "No Reports Found !"}
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
            <Footer />
            <ScrollToTop />
          </div>
        </Layout>
      </RequireAuth>
    </React.Fragment>
  );
}
