import { Redirect, useHistory } from "react-router-dom";

export const RequireAuth = ({ children }) => {
  const history = useHistory();
  let token = localStorage.getItem("token");

  return token ? (
    children
  ) : (
    <>
      <Redirect to="/login" replace state={{ path: history.pathname }} />
    </>
  );
};
