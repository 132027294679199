import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./assets/css/bootstrap.min.css";
import "./assets/scss/style.scss";
import "./assets/css/icofont.css";
import "swiper/components/navigation/navigation.scss";
import "swiper/swiper.scss";
import "./assets/css/animate.css";
import "./assets/css/custom.css";
import "lightgallery.js/dist/css/lightgallery.css";
import "swiper/components/pagination/pagination.scss";
import NavScrollTop from "./components/nav-scroll-top";

import HomePage from "./pages/index";
import Login from "./pages/login";
import ServicePage from "./pages/service";
import ContactPage from "./pages/contact";
import DoctorsPage from "./pages/doctors";
import LabReports from "./pages/labReports";
import ReportsFile from "./pages/ReportsFile";
import News from "./pages/news";
import NewsDetail from "./pages/newsDetail";
import Gallery from "./pages/gallery";
import About from "./pages/about";
import TeamsPage from "./pages/teams";

const App = () => {
  useEffect(() => {
    AOS.init({
      offset: 80,
      duration: 1000,
      once: true,
      easing: "ease",
    });
    AOS.refresh();
  }, []);
  return (
    <Router>
      <NavScrollTop>
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path={"/about"} component={About} />
          <Route path={"/services"} component={ServicePage} />
          <Route path={"/contact"} component={ContactPage} />
          <Route path={"/doctors"} component={DoctorsPage} />
          <Route path={"/teams"} component={TeamsPage} />
          <Route path={"/news"} component={News} />
          <Route path={"/news-detail/:id"} component={NewsDetail} />
          <Route path={"/gallery"} component={Gallery} />
          <Route path={"/gallery-detail/:id"} component={NewsDetail} />
          <Route path={"/login"} component={Login} />
          <Route path={"/lab-reports"} component={LabReports} />
          <Route path={"/lab-report/:id"} component={ReportsFile} />
        </Switch>
      </NavScrollTop>
    </Router>
  );
};

export default App;
