import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { getImageUrl } from "../../globalFunctions";

export default function NoticeModal({ data }) {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Notice</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex flex-column gap-4">
            <span style={{ color: "black" }}>{data.title}</span>
            <img
              src={getImageUrl(data.photo)}
              alt="notice-img"
              style={{ maxWidth: "768px", objectFit: "contain" }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
