export const contactInfo = {
  name: "Rapti Lifecare Hospital Pvt. Ltd.",
  address: "Motipur 7, Dang",
  email: "raptilifecare2063@gmail.com",
  phone: "082-590475",
  phone2: "082-522686",
  timing: "24/7 Every day of the week",
  facebook: "",
  instagram: "",
  twitter: "",
  excerpt:
    "Lorem ipsum dolor sit amet, consect adipisicing elit vero. Donec ultri sollicitudin lacus",
};
