import axios from "axios";
import { baseUrl } from "../globalFunctions";

const endpoint = `${baseUrl}/team`;

export const getTeams = () => {
    const options = {
        method: "get",
        url: `${endpoint}`,
    };
    return axios(options);
};

export const getTeamById = (data) => {
    const options = {
        method: "get",
        url: `${endpoint}/${data.id}`,
    };
    return axios(options);
};
