import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Loader from "../components/loader";
import SEO from "../components/seo";
import { contactInfo } from "../data/contactInfo";
import { getImageUrl } from "../globalFunctions";
import Layout from "../layouts";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import { getSectionById } from "../repositories/section";
import { ImageGroup, Image } from "react-fullscreen-image";

export default function NewsDetail() {
  const { id } = useParams();
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getSectionById({ id });
        setNews(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <Layout>
        <SEO title={`News | ${contactInfo?.name}`} />
        <div className="wrapper">
          <Header />
          <div className="team-area team-default-area">
            <div className="container">
              {!loading ? (
                <>
                  <h1 className="news-title mb-4">{news.title}</h1>
                  <div className="news-desc" dangerouslySetInnerHTML={{ __html: news.content }} />
                  {/* <div className="news-desc">{news.content}</div> */}
                  <div className="mt-4">
                    {news?.images?.length > 0 ? (
                      <>
                        <ImageGroup>
                          <ul className="row">
                            {news?.images?.map((item) => (
                              <>
                                <li
                                  key={item?.image_guid}
                                  className={"col-md-6 col-lg-3"}
                                  style={{
                                    position: "relative",
                                    height: "200px",
                                  }}
                                >
                                  <Image
                                    src={getImageUrl(item.image_url)}
                                    alt="image"
                                    style={{
                                      position: "absolute",
                                      top: 0,
                                      left: 0,
                                      right: 0,
                                      bottom: 0,
                                      height: "100%",
                                      width: "100%",
                                      objectFit: "cover",
                                      padding: "5px",
                                    }}
                                  />
                                </li>
                              </>
                            ))}
                          </ul>
                        </ImageGroup>
                      </>
                    ) : (
                      "No items found !"
                    )}
                  </div>
                </>
              ) : (
                <Loader />
              )}
            </div>
          </div>
          <Footer />
        </div>
      </Layout>
    </React.Fragment>
  );
}
