import axios from "axios";
import { baseUrl } from "../globalFunctions";

const endpoint = `${baseUrl}/notice`;

export const getNotices = () => {
  const options = {
    method: "get",
    url: `${endpoint}?status=active`,
  };
  return axios(options);
};

export const getNoticeById = (data) => {
  const options = {
    method: "get",
    url: `${endpoint}/${data.id}`,
  };
  return axios(options);
};
