import React from "react";

const GoogleMap = () => {
  return (
    <iframe
      title="this is title"
      className="contact-map"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13944.592705284604!2d82.31309124972113!3d28.129229194813743!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3997f379186ac57d%3A0xc135785250ed0060!2sMotipur!5e0!3m2!1sen!2snp!4v1733834799625!5m2!1sen!2snp"
      loading="lazy"
      aria-hidden="false"
    ></iframe>
  );
};

export default GoogleMap;
