import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const MainMenu = () => {
  let location = useLocation();
  let pathname = location?.pathname;
  return (
    <nav>
      <ul className="main-menu">
        <li className={`${pathname === "/" && "active"}`}>
          <NavLink className="main-menu-link" exact to="/">
            Home
          </NavLink>
        </li>
        <li className={`${pathname === "/about" && "active"}`}>
          <NavLink className="main-menu-link" to={"/about"}>
            About
          </NavLink>
        </li>
        <li className={`${pathname === "/services" && "active"}`}>
          <NavLink className="main-menu-link" to={"/services"}>
            Services
          </NavLink>
        </li>
        <li className={`${pathname === "/doctors" && "active"}`}>
          <NavLink className="main-menu-link" to={"/doctors"}>
            Doctors
          </NavLink>
        </li>
        <li className={`${pathname === "/teams" && "active"}`}>
          <NavLink className="main-menu-link" to={"/teams"}>
            Teams
          </NavLink>
        </li>
        <li className={`${pathname === "/news" && "active"}`}>
          <NavLink className="main-menu-link" to={"/news"}>
            News
          </NavLink>
        </li>
        <li className={`${pathname === "/gallery" && "active"}`}>
          <NavLink className="main-menu-link" to={"/gallery"}>
            Gallery
          </NavLink>
        </li>
        <li className={`${pathname === "/contact" && "active"}`}>
          <NavLink className="main-menu-link" to={"/contact"}>
            Contact
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default MainMenu;
