import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { contactInfo } from "../../../data/contactInfo";
import { getClosest, getSiblings, slideToggle, slideUp } from "../../../utils";
import Button from "../../button";
import SocialIcon from "../../social-icon";

const MobileMenu = ({ show, onClose }) => {
  const onClickHandler = (e) => {
    const target = e.currentTarget;
    const parentEl = target.parentElement;
    if (
      parentEl?.classList.contains("menu-expand") ||
      target.classList.contains("menu-expand")
    ) {
      const element = target.classList.contains("icon") ? parentEl : target;
      const parent = getClosest(element, "li");
      const childNodes = parent.childNodes;
      const parentSiblings = getSiblings(parent);
      parentSiblings.forEach((sibling) => {
        const sibChildNodes = sibling.childNodes;
        sibChildNodes.forEach((child) => {
          if (child.nodeName === "UL") {
            slideUp(child, 1000);
          }
        });
      });
      childNodes.forEach((child) => {
        if (child.nodeName === "UL") {
          slideToggle(child, 1000);
        }
      });
    }
  };
  return (
    <div
      className={`offcanvas offcanvas-mobile-menu ${
        show ? "offcanvas-open" : ""
      }`}
    >
      <div className="inner">
        <div className="border-bottom mb-3 pb-3 text-end">
          <button className="offcanvas-close" onClick={onClose}>
            ×
          </button>
        </div>
        <div className="offcanvas-head mb-3">
          <div className="header-top-offcanvas">
            <p>
              <i className="icofont-google-map"></i> <span>ADDRESS:</span>{" "}
              {contactInfo?.address}
            </p>
          </div>
        </div>
        <nav className="offcanvas-menu">
          <ul>
            <li>
              <NavLink exact to={"/"}>
                <span className="menu-text">Home</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/about"}>
                <span className="menu-text">About</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/services"}>
                <span className="menu-text">Services</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/doctors"}>
                <span className="menu-text">Doctors</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/teams"}>
                <span className="menu-text">Teams</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/news"}>
                <span className="menu-text">News</span>
              </NavLink>
            </li>
            <li>
              <NavLink to={"/gallery"}>
                <span className="menu-text">Gallery</span>
              </NavLink>
            </li>

            <li>
              <NavLink to={"/contact"}>Contact Us</NavLink>
            </li>
          </ul>
        </nav>
        <Button
          path={"/login"}
          classOption="book-now-btn mt-2"
          text="Online Lab Report"
        />
        <div className="offcanvas-social my-4">
          <ul>
            <li>
              <SocialIcon
                path={`https://www.facebook.com/${contactInfo?.facebook}`}
                icon="icofont-facebook"
              />
            </li>
            <li>
              <SocialIcon
                path={`https://www.instagram.com/${contactInfo?.instagram}`}
                icon="icofont-instagram"
              />
            </li>
            <li>
              <SocialIcon
                path={`https://twitter.com/${contactInfo?.twitter}`}
                icon="icofont-twitter"
              />
            </li>
          </ul>
        </div>

        <ul className="media-wrap">
          <li className="media media-list">
            <span className="media-icon">
              <i className="icofont-clock-time"></i>
            </span>
            <div className="media-content">
              <span className="media-sub-heading">working hours</span>
              <span className="media-heading">{contactInfo?.timing}</span>
            </div>
          </li>

          <li className="media media-list">
            <span className="media-icon">
              <i className="icofont-ui-call"></i>
            </span>
            <div className="media-content">
              <span className="media-sub-heading">Hotline 24/7</span>
              <div className="d-flex gap-2">
                <a className="media-heading" href={`tel:${contactInfo?.phone}`}>
                  {contactInfo?.phone}
                </a>
                {contactInfo?.phone2 && (
                  <a
                    className="media-heading"
                    href={`tel:${contactInfo?.phone2}`}
                  >
                    / {contactInfo?.phone2}
                  </a>
                )}
              </div>
            </div>
          </li>

          <li className="media media-list">
            <span className="media-icon">
              <i className="icofont-envelope"></i>
            </span>
            <div className="media-content">
              <span className="media-sub-heading">email us</span>
              <a
                className="media-heading"
                href={`mailto:${contactInfo?.email}`}
              >
                {contactInfo?.email}
              </a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

MobileMenu.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
};

export default MobileMenu;
