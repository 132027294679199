import React, { useEffect, useState } from "react";
import Layout from "../layouts/index.jsx";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import { contactInfo } from "../data/contactInfo.js";
import { getReportPdf } from "../repositories/labReport.jsx";
import { dateFormatter, fileUrl, getImageUrl } from "../globalFunctions.js";
import { useParams } from "react-router-dom";
import Loader from "../components/loader/index.js";
import { RequireAuth } from "../hoc/requireAuth.js";

export default function ReportsFile() {
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [tests, setTests] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getReportPdf({ id });
        setTests(response.data.data?.lab_tests);
        setFiles(response.data.data?.reports);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <RequireAuth>
        <Layout>
          <SEO title={`Files | ${contactInfo?.name}`} />
          <div className="wrapper">
            <Header />
            <div
              className="team-area team-default-area bg-gray"
              style={{ paddingTop: "50px" }}
            >
              <div className="container">
                <div>
                  {!loading ? (
                    <div className="row mt-2">
                      <div className="col-md-6">
                        <h2 className="section-header">Lab Tests</h2>
                        <div className="d-flex flex-column gap-2 mt-2">
                          {tests?.length > 0
                            ? tests?.map((item) => (
                              <li
                                key={item.report_guid}
                                style={{
                                  color: "black",
                                }}
                              >
                                {item.name}
                              </li>
                            ))
                            : "No lab tests found !"}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <h2 className="section-header">Lab Reports</h2>
                        <div className="d-flex flex-column gap-2 mt-2">
                          {files?.length > 0
                            ? files?.map((item, i) => (
                              <a
                                href={`${getImageUrl(item.pdf_url)}`}
                                target="_blank"
                                key={item.pdf_guid}
                                className="d-flex align-items-center gap-2"
                                style={{
                                  color: "var(--primary-color)",
                                  cursor: "pointer",
                                }}
                              >
                                <img src="/img/pdf.svg" style={{ height: '20px', objectFit: 'contain' }} alt="pdf" loading="lazy" />
                                {`Report ${i + 1}`}
                              </a>
                            ))
                            : "No reports found !"}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <Loader />
                  )}
                </div>
              </div>
            </div>
            <Footer />
            <ScrollToTop />
          </div>
        </Layout>
      </RequireAuth>
    </React.Fragment>
  );
}
