import React, { useEffect, useState } from "react";
import Layout from "../layouts/index.jsx";
import Header from "../layouts/header";
import Footer from "../layouts/footer";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import { contactInfo } from "../data/contactInfo.js";
import SectionTitle from "../components/section-title/index.jsx";
import Team from "../components/team";
import TeamData from "../data/team.json";
import { getDoctors } from "../repositories/doctor.jsx";
import Loader from "../components/loader/index.js";

export default function DoctorsPage() {
  const [loading, setLoading] = useState(false);
  const [doctors, setDoctors] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDoctors();
        setDoctors(response?.data?.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <Layout>
        <SEO title={`Doctors | ${contactInfo?.name}`} />
        <div className="wrapper">
          <Header />
          <div className="team-area team-default-area bg-gray">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <SectionTitle
                    classOption="text-center"
                    subTitle="Meet Our Doctors"
                    title="<span>Professional &amp;</span> Enthusiastic"
                  />
                </div>
              </div>
              {!loading ? (
                <div>
                  <div
                    className="row"
                    data-aos="fade-up"
                    data-aos-duration="1300"
                  >
                    {doctors?.map((single, key) => (
                      <div className="col-md-6 col-lg-4 mb-4">
                        <Team key={key} data={single} />
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <Loader />
              )}
            </div>
          </div>
          <Footer />
        </div>
      </Layout>
    </React.Fragment>
  );
}
