import PropTypes from "prop-types";
import { altImage, getImageUrl } from "../../globalFunctions";

const Team = ({ data, teamCard }) => {
  return (
    <div className="team-member">
      <div className="thumb">
        <img
          src={data.photo ? `${getImageUrl(data.photo)}` : altImage}
          alt="RLH"
        />
      </div>
      <div className="content">
        <div className="member-info d-flex flex-column">
          <h4 className="name mb-2">{`${data.designation}${data.first_name} ${data.middle_name} ${data.last_name}`}</h4>
          <span className="fw-bold">{data.department_name}</span>
          <span>{data.service}</span>
          {!teamCard && <span>NMC Number: {data.nmc_no}</span>}
        </div>
      </div>
    </div>
  );
};

Team.propTypes = {
  data: PropTypes.object,
};

export default Team;
