import React, { useState } from "react";
import { addContact } from "../../repositories/contact";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [result, setResult] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setResult("");
    setLoading(true);
    try {
      let data = {
        full_name: name,
        phone: phone,
        email: email,
        message: message,
      };
      await addContact(data);
      setResult("success");
      resetForm();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error(error);
      setResult("error");
    }
    setTimeout(() => {
      setResult("");
    }, 5000);
  };

  const resetForm = () => {
    setName("");
    setEmail("");
    setPhone("");
    setMessage("");
  };
  return (
    <form
      className="contact-form-wrapper"
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      data-aos="fade-up"
      data-aos-duration="1200"
    >
      <div className="row">
        <div className="col-md-4">
          <div className="form-group">
            <input
              className="form-control"
              type="text"
              name="name"
              placeholder="Your Name *"
              required
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input
              className="form-control"
              type="email"
              name="email"
              placeholder="Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-4">
          <div className="form-group">
            <input
              className="form-control"
              type="tel"
              name="phone"
              placeholder="Phone Number *"
              pattern="[7-9]{1}[0-9]{9}"
              required
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
          </div>
        </div>
        <div className="col-md-12">
          <div className="form-group mb-0">
            <textarea
              name="message"
              rows="5"
              placeholder="Write your message here *"
              required
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            ></textarea>
          </div>
        </div>
        <div className="col-md-12 text-center">
          <div className="form-group mb-0">
            <button
              className="btn btn-theme btn-block custom-btn"
              disabled={loading}
              type="submit"
            >
              Send Message
            </button>
          </div>
          <br />
          {result === "success" ? (
            <span className="text-success">
              Message sent successfully ! Thank you for contacting us.
            </span>
          ) : result === "error" ? (
            <span className="text-danger">
              Something went wrong ! Please try again.
            </span>
          ) : (
            ""
          )}
        </div>
      </div>
    </form>
  );
};

export default ContactForm;
