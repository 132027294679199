import SectionTitle from "../../../components/section-title";

const Values = () => {
    return (
        <section className="feature-section bg-white">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            classOption="text-center"
                            subTitle="Our Objectives"
                            title="<span>Compassion Drives </span> Hospital Excellence"
                        />
                    </div>
                </div>
                <div className="row pb-20"
                    data-aos="fade-right"
                    data-aos-duration="1300"
                >
                    <div className="col-lg-12">
                        <ul className="obj-list">
                            <li>Patient orientation</li>
                            <li>Team approach</li>
                            <li>Mutual trust and Respect Individuals</li>
                            <li>Performance driven with compassion as well as with empathy and, honesty and openness</li>
                            <li>Excellence in health care service and education Quality, technology and innovation</li>
                            <li>Better co ordination and communication</li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Values;
