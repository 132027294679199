import axios from "axios";
import { baseUrl } from "../globalFunctions";

const endpoint = `${baseUrl}/user`;

// assign token if authorized
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Authorization"] =
  "Bearer " + localStorage.getItem("token");

export const postLogin = (data) => {
  const options = {
    method: "post",
    url: `${endpoint}/authenticate`,
    data: data,
  };
  return axios(options);
};
