import SectionTitle from "../../../components/section-title";
import medicalFeatures from "../../../data/medicalFeatures.json";
import MedicalFeature from "../../../components/medical-feature";

const MedicalFeatureContainer = () => {
    return (
        <section className="feature-section bg-white">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <SectionTitle
                            classOption="text-center"
                            subTitle="Why Choose Rapti Lifecare Hospital?"
                            title="<span>The Best</span> For Your Health"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div
                            className="row icon-box-style"
                            data-aos="fade-up"
                            data-aos-duration="1100"
                        >
                            {medicalFeatures?.map((single, key) => {
                                return (
                                    <div key={key} className="col-md-6">
                                        <MedicalFeature
                                            key={key}
                                            data={single}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default MedicalFeatureContainer;
