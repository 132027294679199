import React from "react";
import { Link } from "react-router-dom";
import { contactInfo } from "../../data/contactInfo";

const ContactAddress = () => {
  return (
    <div className="contact-info-content">
      <div className="info-address">
        <h2 className="title">{contactInfo?.name}</h2>
        <p>Address: {contactInfo?.address}</p>
        <Link to="/">
          <span>Email:</span> {contactInfo?.email}
        </Link>
      </div>
      <div className="brand-office">
        <div className="info-tem style-two">
          <h6>Call directly:</h6>
          <p>
            <a className="text-white" href={`tel:${contactInfo?.phone}`}>
              {contactInfo?.phone}
            </a>
          </p>
          <p className="mt-4">
            <a className="text-white" href={`tel:${contactInfo?.phone2}`}>
              {contactInfo?.phone2}
            </a>
          </p>
        </div>
        <div className="info-tem mb-0">
          <h6>Work Hours:</h6>
          <p>{contactInfo?.timing}</p>
        </div>
      </div>
    </div>
  );
};

export default ContactAddress;
