import axios from "axios";
import { baseUrl } from "../globalFunctions";

const endpoint = `${baseUrl}/contact`;

export const addContact = (data) => {
  const options = {
    method: "post",
    url: `${endpoint}`,
    data: data,
  };
  return axios(options);
};
