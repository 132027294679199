import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Pagination } from "swiper";
import TeamData from "../../../data/team.json";
import Team from "../../../components/team";
import SectionTitle from "../../../components/section-title";
import Button from "../../../components/button";
import { getDoctors } from "../../../repositories/doctor";
import { useEffect, useState } from "react";
import Loader from "../../../components/loader";

SwiperCore.use([Pagination]);
const TeamContainer = () => {
  const [loading, setLoading] = useState(false);
  const [doctors, setDoctors] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getDoctors();
        setDoctors(response?.data?.data);
        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const swiperOption = {
    loop: true,
    speed: 600,
    spaceBetween: 30,
    slidesPerView: 3,
    pagination: { clickable: true },
    breakpoints: {
      1200: {
        slidesPerView: 3,
      },

      991: {
        slidesPerView: 2,
      },

      767: {
        slidesPerView: 2,
      },

      560: {
        slidesPerView: 2,
      },

      0: {
        slidesPerView: 1,
      },
    },
  };
  return (
    <div className="team-area team-default-area bg-gray">
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <SectionTitle
              classOption="text-center"
              subTitle="Meet Our Doctors"
              title="<span>Professional &amp;</span> Enthusiastic"
            />
          </div>
        </div>
        <div className="row">
          {!loading ? (
            <div
              className="col-lg-12"
              data-aos="fade-up"
              data-aos-duration="1300"
            >
              <Swiper className="team-slider-container" {...swiperOption}>
                {doctors?.map((single, key) => {
                  return (
                    <SwiperSlide key={key}>
                      <Team key={key} data={single} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          ) : (
            <Loader />
          )}
        </div>
        <div className="d-flex justify-content-center">
          <Button
            path={"/doctors"}
            classOption="book-now-btn mt-4"
            text="View All"
          />
        </div>
      </div>
    </div>
  );
};

export default TeamContainer;
