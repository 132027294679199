import axios from "axios";
import { baseUrl } from "../globalFunctions";

const endpoint = `${baseUrl}/doctor`;

export const getDoctors = () => {
  const options = {
    method: "get",
    url: `${endpoint}`,
  };
  return axios(options);
};

export const getDoctorById = (data) => {
  const options = {
    method: "get",
    url: `${endpoint}/${data.id}`,
  };
  return axios(options);
};
