import React, { useEffect, useState } from "react";
import Layout from "../layouts/index.jsx";
import Header from "../layouts/header";
import IntroContainer from "../containers/home/intro";
import HealthFeatureContainer from "../containers/home/health-feature";
import MedicalFeatureContainer from "../containers/home/medical-feature";
import TeamContainer from "../containers/home/team";
import ServiceContainer from "../containers/service-box";
import GoogleMapContainer from "../containers/global/map";
import Footer from "../layouts/footer";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import { contactInfo } from "../data/contactInfo.js";
import NoticeModal from "../components/modal/NoticeModal";
import { getNotices } from "../repositories/notice.jsx";
import Objectives from "../containers/home/objectives/index.js";
import Values from "../containers/home/values/index.js";

const HomePage = () => {
  const [notices, setNotices] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getNotices();
        setNotices(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);
  return (
    <React.Fragment>
      <Layout>
        <SEO title={contactInfo?.name} />

        <div className="wrapper home-default-wrapper">
          <Header />
          <div className="main-content site-wrapper-reveal">
            <IntroContainer />
            <HealthFeatureContainer />
            <MedicalFeatureContainer />
            <ServiceContainer />
            <TeamContainer />
            {/* <TestimonialContainer /> */}
            <Objectives />
            <Values />
            <GoogleMapContainer />
          </div>
          <Footer />
          <ScrollToTop />
          {notices?.length > 0 &&
            notices?.map((item) => (
              <NoticeModal key={item.notice_guid} data={item} />
            ))}
        </div>
      </Layout>
    </React.Fragment>
  );
};

export default HomePage;
