import React, { useEffect, useState } from "react";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";
import { contactInfo } from "../data/contactInfo";
import Layout from "../layouts";
import Footer from "../layouts/footer";
import Header from "../layouts/header";
import { useHistory } from "react-router-dom";
import SectionTitle from "../components/section-title";
import { postLogin } from "../repositories/user";
import axios from "axios";

export default function Login() {
  const history = useHistory();
  const [errorResponse, setErrorResponse] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [user, setUser] = useState(null);

  const handleSubmit = async () => {
    setErrorResponse("");
    setIsSubmitting(true);
    let data = {
      username: id,
      password: password,
    };
    try {
      const response = await postLogin(data);
      let token = response?.data?.data?.token;
      if (token) {
        // assign token if authorized
        axios.defaults.headers.common["Content-Type"] = "application/json";
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(response?.data?.data));
        localStorage.setItem("patientId", id);
        history.replace("/lab-reports");
      }
      setIsSubmitting(false);
    } catch (error) {
      setIsSubmitting(false);
      console.error(error);
      const message = error?.response?.data?.message || null;
      setErrorResponse(message || "Something went wrong !");
    }
  };

  useEffect(() => {
    let t = JSON.parse(localStorage.getItem("user"));
    setUser(t || "");
  });

  return (
    <React.Fragment>
      <Layout>
        <SEO title={`Login | ${contactInfo?.name}`} />
        <div className="wrapper">
          <Header />
          <div className="main-content site-wrapper-reveal">
            <div className="login_container">
              <div className="d-flex flex-column align-items-center">
                <div className="row">
                  <div className="col-lg-12">
                    <SectionTitle
                      classOption="text-center"
                      subTitle=""
                      title="<span>Online Lab</span> Report"
                    />
                  </div>
                </div>
                <form
                  className="login_form"
                  data-aos="fade-up"
                  onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit();
                  }}
                >
                  <div>
                    <label htmlFor="username">Patient Id</label>
                    <input
                      type="text"
                      className="login_input"
                      required
                      value={id}
                      onChange={(e) => setId(e.target.value)}
                    />
                  </div>

                  <div>
                    <label htmlFor="password">Password</label>
                    <input
                      className={`login_input`}
                      type={showPassword ? "text" : "password"}
                      required
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                  <div className="show_password">
                    <input
                      type="checkbox"
                      id="checkbox"
                      name="checkbox"
                      checked={showPassword}
                      onChange={() => setShowPassword(!showPassword)}
                    />
                    <label
                      htmlFor="checkbox"
                      style={{
                        marginLeft: "-5px",
                        paddingLeft: "5px",
                        fontSize: "16px",
                      }}
                    >
                      Show Password
                    </label>
                  </div>
                  <input
                    type="submit"
                    className="login_btn"
                    value="Login"
                    disabled={isSubmitting}
                  />
                  {user && (
                    <button
                      className="continue-btn login_btn mt-2"
                      onClick={() => history.replace("/lab-reports")}
                      disabled={isSubmitting}
                    >
                      {`Continue as ${user.username}`}
                    </button>
                  )}
                  {errorResponse && (
                    <span
                      className="text-danger mt-2 text-center"
                      style={{ fontSize: "14px" }}
                    >
                      {errorResponse}
                    </span>
                  )}
                </form>
              </div>
            </div>
          </div>
          <Footer />
          <ScrollToTop />
        </div>
      </Layout>
    </React.Fragment>
  );
}
